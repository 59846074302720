<template>
  <div>
    <b-row v-if="options.length > 0">
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="options"
            :fields="fields"
            @row-dblclicked="editForm"
            @row-clicked="clicked"
            @row-contextmenu="handleContextMenuEvent"
            head-row-variant="secondary"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
          </b-table>
          <vue-context ref="menu">
            <li>
              <b-link
                class="d-flex align-items-center"
                @click="deleteTypePhoto"
              >
                <feather-icon icon="DeleteIcon" />
                <span class="ml-75">Удалить</span>
              </b-link>
            </li>
          </vue-context>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import VueContext from "vue-context";
export default {
  components: {
    VueContext,
  },
  props: ["options"],
  data() {
    return {
      fields: [
        { key: "id", label: "ID", sortable: true },
        { key: "name", label: "имя", sortable: true },
        {
          key: "alias_for_file_name",
          label: "псевдоним для имени файла",
          sortable: true,
        },
        { key: "description", label: "описание", sortable: true },
        {
          key: "created_at",
          label: "создан ",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "updated_at",
          label: "обработан",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
      id: null,
      index: null,
    };
  },
  mounted() {
    resizeable();
  },
  methods: {
    editForm(item) {
      this.$emit("editForm", item.id);
    },
    deleteTypePhoto() {
      this.$http
        .delete(`photo-control/type/${this.id}`)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успешно удалено!",
              icon: "CheckIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          this.$emit("refresh");
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Некорректные данные!",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.errors,
            },
          });
        });
    },
    handleContextMenuEvent(item, index, event) {
      this.id = item.id;
      event.preventDefault();
      this.$refs.menu.open(event);
    },
    clicked(item, index, event) {
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }

      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;
      this.$store.state.filterPlus.filterPlus = event.target.innerHTML.trim();
      cellSelect();
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/table.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
